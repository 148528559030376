import trash from './trash';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function init() {
  const hero = document.querySelector('#home-hero');
  if (!hero) {
    return;
  }

  const titleSpans = Array.from(document.querySelector('#home-title').children);
  const taglineSpans = Array.from(document.querySelector('#home-tagline').children);
  const otherEls = Array.from(document.querySelectorAll('.js-home-animate-in, .js-home-animate-in-children > *'));
  const tl = gsap.timeline();
  tl
  .set(otherEls, {y: 20, opacity: 0})
  .from(titleSpans, {
    opacity: 0,
    yPercent: 20,
    duration: 0.2,
    stagger: 0.1,
    delay: 0.2,
    ease: "back.out(1.7)"
  })
  .from(taglineSpans, {
    opacity: 0,
    yPercent: 20,
    duration: 0.2,
    stagger: 0.05,
    delay: 0.5,
    ease: "back.out(1.7)"
  })
  // insert at the START of the previous animation
  .call(() => { trash.init() }, null, "<")
  .to(hero, {
    opacity: 0,
    display: 'none',
    duration: 0.2,
    delay: 5,
  })
  .add('end')
  .call(() => {
    ScrollTrigger.batch(otherEls, {
      onEnter: (els) => {
        gsap.to(els, {
          y: 0,
          opacity: 1,
          duration: 0.2,
          stagger: 0.05,
          ease: 'back.out(1.7)',
        });
      },
      once: true,
    });
  }, null, 'end');
}

init();
