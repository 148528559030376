import Matter from 'matter-js';
import debounce from 'lodash';

let timeouts = [];

const animationLength = 3000; // ms
let counter = 0;
let	counterEnd = 350;
let countInterval = animationLength / counterEnd;
let a = 1.05;

let summatory = 0;

const {
  Engine,
  Render,
  Runner,
  World,
  Bodies,
} = Matter;

const scale = () => {
  if (window.innerWidth > 1280) {
    return 1;
  } else if (window.innerWidth > 768) {
    return 0.75;
  } else {
    return 0.5;
  }
}

const trash = {
  images: [
    {
      texture: `../images/cup@1.png`,
      width: 113 * 0.5 * scale(),
      height: 152 * 0.5 * scale(),
      yOffset: 0.25,
      cols: 12,
      rows: 8,
    },
    {
      texture: `../images/bottle@1.png`,
      width: 78 * 0.5 * scale(),
      height: 219 * 0.5 * scale(),
      yOffset: 0,
      cols: 12,
      rows: 5,
    },
    {
      texture: `../images/bag@1.png`,
      width: 146 * 0.5 * scale(),
      height: 179 * 0.5 * scale(),
      yOffset: 0,
      cols: 12,
      rows: 4,
    },
  ],

  animate() {
    const xOffset = this.cWidth * 0.05 + this.cWidth * 0.9 * Math.random();
    const image = this.images[Math.floor(this.images.length * Math.random())];

    const body = Bodies.rectangle(xOffset, -300, image.width, image.height, {
      // torque: Math.random() * 2 - 1,
      torque: Math.random(),
      render: {
        lineWidth: 2,
        strokeStyle: 'red',
        fillStyle: '#ffffff',
        sprite: {
          yOffset: image.yOffset,
          texture: image.texture,
          xScale: scale(),
          yScale: scale(),
        },
      },
    });

    World.add(this.world, body);

    if (counter <= counterEnd) {
      counter++;
      const newInterval = (animationLength-summatory) / ( (a - Math.pow(a, -(counterEnd-1))) / (a-1));
      summatory += newInterval;
      countInterval = newInterval;
      timeouts.push(setTimeout(function() {
        this.animate();
      }.bind(this), newInterval));
    } else {
      // TODO: detect when last trash item is out of viewport and destroy trash canvas
      timeouts.forEach(timeout => {
        window.clearTimeout(timeout);
      })
    }
  },

  setupWorld() {
    this.world.bodies = [];
    this.boundT = Bodies.rectangle(this.cWidth / 2, this.offset, this.cWidth, this.wallSize, this.options);
    this.boundB = Bodies.rectangle(
      this.cWidth / 2,
      this.cHeight - this.offset,
      this.cWidth,
      this.wallSize,
      this.options
    );
    this.boundR = Bodies.rectangle(this.cWidth, this.cHeight / 2, this.wallSize, this.cHeight, this.options);
    this.boundL = Bodies.rectangle(0, this.cHeight / 2, this.wallSize, this.cHeight, this.options);

    World.add(this.world, [this.boundL, this.boundR]);
  },

  handleResize: debounce(() => {
    if (trash.canvas) {
      trash.clear();
      trash.init();
    }
  }, 250),

  init() {
    this.canvas = document.getElementById(`trash-canvas`);

    if (this.canvas) {
      this.engine = Engine.create({
        enableSleeping: true
      });
      this.world = this.engine.world;
      this.cWidth = document.documentElement.clientWidth;
      this.cHeight = document.documentElement.clientHeight;
      this.wallSize = 2;
      this.background = 'transparent';
      this.offset = 5;
      this.gutter = 160;
      this.rowGap = 30;
      this.xx = this.gutter / 2;
      this.yy = -630;
      this.options = {
        isStatic: true,
        render: {
          fillStyle: this.background,
        },
      };
      this.trashIndex = null;
      this.windowWidth = window.innerWidth;

      // create renderer
      this.render = Render.create({
        canvas: this.canvas,
        engine: this.engine,
        options: {
          width: this.cWidth,
          height: this.cHeight,
          background: this.background,
          showAngleIndicator: false,
          wireframes: false,
          showSleeping: false,
          hasBounds: true,
          showDebug: false,
        },
      });

      Render.setPixelRatio(this.render, 'auto')
      Render.run(this.render);

      // create runner
      const runner = Runner.create();
      Runner.run(runner, this.engine);

      this.setupWorld();
      this.animate();

      // fit the render viewport to the scene
      Render.lookAt(this.render, {
        min: { x: 0, y: 0 },
        max: { x: this.cWidth, y: this.cHeight },
      });

      const handleResize = this.handleResize;

      window.removeEventListener(`resize`, handleResize);
      window.addEventListener(`resize`, handleResize);

      // if (window.DeviceOrientationEvent) {
      //   window.removeEventListener('deviceorientation', updateGravity);
      //   window.addEventListener('deviceorientation', updateGravity);
      // }
    }
  },

  clear() {
    if (this.canvas) {
      Matter.Render.stop(this.render); // this only stop renderer but not destroy canvas
      Matter.World.clear(this.engine.world);
      Matter.Engine.clear(this.engine);
      this.render.canvas = null;
      this.render.context = null;
      this.render.textures = {};
      this.canvas = null;
    }
  },

  destroy() {
    if (this.canvas) {
      Matter.Render.stop(this.render); // this only stop renderer but not destroy canvas
      Matter.World.clear(this.engine.world);
      Matter.Engine.clear(this.engine);
      this.render.canvas.remove();
      this.render.canvas = null;
      this.render.context = null;
      this.render.textures = {};
      this.canvas = null;
    }
  }
};

export default trash;
